import React from "react";
import { useLink } from "solito/link";

import {
  CalendarText,
  Circle,
  type IssueStatus,
  Paragraph,
  PillIcon,
  type PillIconProps,
  XStack,
  YStack,
  styled,
} from "@medbillai/ui";

import { issueUrl } from "@medbillai/utils/urls";

import type { CaseListProps, CaseProps } from "../utils/types";
import { cleanMarkdownText, formatEvent, getTimeColor } from "../utils/utils";

const DateTextStyle = styled(Paragraph, {
  fontSize: "$1",
  fontWeight: "500",
  whiteSpace: "nowrap",
  textAlign: "left",
});

const StyledCircle = styled(Circle, {
  backgroundColor: "#650FA9",
  size: 6,
});

export const getChatPillStyleNew = (status: IssueStatus): PillIconProps => {
  switch (status) {
    case "CLOSED":
      return {
        text: "Resolved",
        wrapperProps: {
          backgroundColor: "$blue3",
        },
        textProps: {
          color: "$blue10",
        },
      };
    case "NEW_ACTIVITY":
      return {
        text: "New Message",
        wrapperProps: {
          backgroundColor: "#F6EBFF",
        },
        textProps: {
          color: "#650FA9",
        },
        icon: <StyledCircle></StyledCircle>,
      };
    default:
      return {
        text: "Processing",
        wrapperProps: {
          backgroundColor: "#79829D1A",
        },
        textProps: {
          color: "#79829D",
        },
      };
  }
};

/**
 * This is basically a copy of caselist, because the current designs are in
 * flux, these will get unified and cleaned during the rebrand
 */
const CaseListItem = ({ issue }: CaseProps) => {
  // If a unseen event exists we know there was new activity on the issue
  const status = issue.closedAt
    ? "CLOSED"
    : issue.firstUnseenEvent
      ? "NEW_ACTIVITY"
      : "PROCESSING";

  const lastEvent = issue.lastEvent;

  // First check if firstUnseenEvent is available,
  // if available display that event formatted,
  // otherwise display most recent event formatted
  const eventContent = formatEvent(issue.firstUnseenEvent || lastEvent);

  return (
    <YStack
      f={1}
      {...useLink({ href: issueUrl(issue.id) })}
      cur="pointer"
      bg="$color1"
      p="$2"
      marginHorizontal="$3"
      borderRadius="$4"
      gap="$3"
      pressStyle={{
        backgroundColor: "#F2F5FF80",
      }}
    >
      <XStack gap="$1" jc="space-between" py="$1" alignItems="center">
        <Paragraph fow="500" f={1} numberOfLines={1} overflow="hidden">
          {issue.title}
        </Paragraph>
        <CalendarText
          date={lastEvent?.sentAt || ""}
          element={props => (
            <DateTextStyle {...props} color={getTimeColor(status)} />
          )}
        />
      </XStack>
      <XStack gap="$2" jc="space-between" alignItems="center">
        <Paragraph
          theme="alt2"
          f={1}
          numberOfLines={1}
          overflow="hidden"
          fontSize="$1"
        >
          {cleanMarkdownText(eventContent)}
        </Paragraph>
        <PillIcon {...getChatPillStyleNew(status)} />
      </XStack>
    </YStack>
  );
};

export const CaseListNew = ({ issues }: CaseListProps) => {
  return (
    <YStack>
      {issues.map(issue => (
        <CaseListItem key={issue.id} issue={issue} />
      ))}
    </YStack>
  );
};
