import React, { useState } from "react";
import type { LayoutChangeEvent } from "react-native";

import type { IconProps } from "@tamagui/helpers-icon";

import {
  AnimatePresence,
  FullscreenSpinner,
  Text,
  XStack,
  styled,
} from "@medbillai/ui";

const IconWrapper = styled(XStack, {
  position: "absolute",
  justifyContent: "center",
  alignItems: "center",
  height: "$2",
  width: "$2",
  animation: "bouncyTight",
  enterStyle: {
    scale: 0,
    opacity: 0,
  },
  exitStyle: {
    scale: 0,
    opacity: 0,
  },
});

export const EmptyRow = ({
  loading,
  description,
  icon: Icon,
}: {
  loading: boolean;
  description: string;
  icon: React.NamedExoticComponent<IconProps>;
}) => {
  const [containerWidth, setContainerWidth] = useState(0);
  // Tamagui bug around animating position so we calulate layout width for transform,
  // this is actually better anyways as its not advised to animate positon directly.
  const onContainerLayout = (event: LayoutChangeEvent) => {
    setContainerWidth(event.nativeEvent.layout.width - 28);
  };

  return (
    <XStack
      marginHorizontal="$4"
      height="$4"
      alignItems="center"
      f={1}
      onLayout={onContainerLayout}
    >
      <XStack
        animation="200ms easeOut"
        left={0}
        position="absolute"
        width="85%"
        opacity={loading ? 0 : 1}
        transform={[{ translateX: loading ? -20 : 0 }]}
        right={20}
      >
        <Text color="$inputTextColorPrimary" fontWeight="500" fontSize="$3">
          {description}
        </Text>
      </XStack>
      <XStack
        width="$2"
        animation="100ms"
        right="$0"
        height="$2"
        position="absolute"
        transform={[{ translateX: loading ? -(containerWidth / 2) : 0 }]}
      >
        <AnimatePresence>
          {loading ? (
            <IconWrapper key="spinner">
              <FullscreenSpinner />
            </IconWrapper>
          ) : (
            <IconWrapper key="circle">
              <Icon color="$inputTextColorPrimary" size="$2" />
            </IconWrapper>
          )}
        </AnimatePresence>
      </XStack>
    </XStack>
  );
};
