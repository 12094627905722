import React, { useCallback } from "react";
import { useRouter } from "solito/router";

import { MessageCircle as MessageCircleIcon } from "@tamagui/lucide-icons";

import { IssueType, type QueryIssuesQuery } from "@medbillai/graphql-types";

import { YBlock, YStack } from "@medbillai/ui";

import { issuePreviewUrl, issuesUrl } from "@medbillai/utils/index";

import { CaseListNew } from "../../cases/components/CaseListNew";
import { EmptyRow } from "./EmptyRow";
import { WidgetHeader } from "./WidgetHeader";

export const CasesWidget = ({
  loading,
  data,
}: {
  loading: boolean;
  data: QueryIssuesQuery | undefined;
}) => {
  const { push } = useRouter();

  const issues = data?.queryIssues;
  const isEmpty = !(issues && issues.length > 0);

  const handlePress = useCallback(() => {
    requestAnimationFrame(() => {
      isEmpty ? push(issuePreviewUrl(IssueType.ONBOARDING)) : push(issuesUrl());
    });
  }, [isEmpty]);

  return (
    <YBlock
      paddingVertical="$4"
      borderRadius="$5"
      paddingHorizontal="$0"
      borderWidth={0.5}
      borderColor="$color5"
    >
      <WidgetHeader
        label="Open Cases"
        buttonLabel={isEmpty ? "Create Case" : "View All"}
        onPress={handlePress}
      />
      <YStack>
        {isEmpty ? (
          <EmptyRow
            loading={loading}
            description="No active cases. Start a chat to discuss any billing or insurance concerns."
            icon={MessageCircleIcon}
          />
        ) : (
          <CaseListNew issues={issues} />
        )}
      </YStack>
    </YBlock>
  );
};
