import React, { memo, useCallback } from "react";
import { useRouter } from "solito/router";

import { CircleDollarSign } from "@tamagui/lucide-icons";

import { YBlock, YStack } from "@medbillai/ui";

import { billsUrl, insuranceLinkUrl } from "@medbillai/utils/urls";

import { BillList } from "../../bill/components/BillList";
import type { NormalizedEob } from "../../bill/utils/types";
import { EmptyRow } from "./EmptyRow";
import { WidgetHeader } from "./WidgetHeader";

const EmptyRowMemo = memo(EmptyRow);

export const BillsWidget = ({
  data,
  loading,
}: {
  data: NormalizedEob[];
  loading: boolean;
}) => {
  const { push } = useRouter();
  const isEmpty = !(data.length > 0);

  const handlePress = useCallback(() => {
    requestAnimationFrame(() => {
      isEmpty ? push(insuranceLinkUrl()) : push(billsUrl());
    });
  }, [isEmpty]);

  return (
    <YBlock
      paddingVertical="$4"
      borderRadius="$5"
      paddingHorizontal="$0"
      borderColor="#F3F4F6"
      borderWidth="$1"
    >
      <WidgetHeader
        label="Recent Bills"
        buttonLabel={isEmpty ? "Connect Insurance" : "View All"}
        onPress={handlePress}
      />
      <YStack>
        {isEmpty ? (
          <EmptyRowMemo
            loading={loading}
            description="No current bills. Connect your insurance to start viewing and
          analyzing your bills."
            icon={CircleDollarSign}
          />
        ) : (
          <BillList eobs={data} homePage={true} />
        )}
      </YStack>
    </YBlock>
  );
};
