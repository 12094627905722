// src/components/SelectableChatOption.tsx
import React, { memo } from "react";

import { Text, XStack } from "@medbillai/ui";

interface SelectableChatOptionProps {
  title: string;
  index: number;
  onPress: (index: number) => void;
}

const SelectableChatOption = ({
  title,
  index,
  onPress,
}: SelectableChatOptionProps) => {
  return (
    <XStack
      padding="$1"
      backgroundColor="#F2F5FFB3"
      borderRadius={8}
      paddingHorizontal="$3"
      paddingVertical="$3"
      marginRight={10} // Adding margin for spacing
      alignItems="center"
      justifyContent="center"
      onPress={() => onPress(index)}
      animation="bouncyTight"
      pressStyle={{ scale: 0.95 }}
      scale={1}
    >
      <Text color="$inputTextColorPrimary" fontWeight="500" fontSize="$1">
        {title}
      </Text>
    </XStack>
  );
};

SelectableChatOption.displayName = "SelectableChatOption";
export default memo(SelectableChatOption);
