import { type IssueStatus, type SelectBarTabs } from "@medbillai/ui";

import type { Issue } from "./types";

export const openFilter = {
  input: {
    open: true,
  },
};

export const unreadFilter = {
  input: {
    unread: true,
  },
};

export const closedFilter = {
  input: {
    closed: true,
  },
};

export const filterOptionIds = {
  open: "open",
  unread: "unread",
  closed: "closed",
};

export const filterOptions: SelectBarTabs = [
  { id: filterOptionIds.open, name: "Open" },
  { id: filterOptionIds.unread, name: "Unread" },
  { id: filterOptionIds.closed, name: "Closed" },
];

export const formatEvent = (event: Issue["lastEvent"]) => {
  switch (event?.__typename) {
    case "issue__ChatEvent_Type":
      return event.chatBody;
    case "issue__ClosedEvent_Type":
      return "Issue closed";
    case "issue__DocumentUploadedEvent_Type":
      return "Document uploaded";
    case "issue__ReopenedEvent_Type":
      return "Issue reopened";
    case "issue__HipaaReleaseRequestedEvent_Type":
      return "HIPAA release requested";
    case "issue__TemplateReleaseRequestedEvent_Type":
      return `${event.template.description} requested`;
    case "issue__BillDisputeEvent_Type":
      return "Bill disputed";
    default:
      return null;
  }
};

export const getTimeColor = (status: IssueStatus) => {
  return status === "NEW_ACTIVITY" ? "$blue9" : "$gray9";
};

export const cleanMarkdownText = (text: string | null) => {
  if (!text) {
    return "";
  }
  return text.replace(/[\\^$*+?.()|[\]{}#]/g, "");
};
