import React from "react";

import { ChevronRight as ChevronRightIcon } from "@tamagui/lucide-icons";

import { Text, XStack } from "@medbillai/ui";

export const WidgetHeader = ({
  label,
  buttonLabel,
  onPress,
}: {
  label: string;
  buttonLabel?: string;
  onPress?: () => void;
}) => {
  return (
    <XStack
      paddingHorizontal="$4"
      minHeight="$4"
      jc="space-between"
      width="100%"
      pb="$2"
      alignItems="center"
    >
      <Text color="$softBlackPrimary" fontSize="$6">
        {label}
      </Text>
      {buttonLabel && onPress ? (
        <NavButton label={buttonLabel} onPress={onPress} />
      ) : null}
    </XStack>
  );
};

export const NavButton = ({
  label,
  onPress,
}: {
  label: string;
  onPress: () => void;
}) => {
  return (
    <XStack
      alignItems="center"
      backgroundColor="#F2F5FF80"
      paddingLeft="$4"
      paddingRight="$2"
      paddingVertical="$2"
      justifyContent="flex-end"
      br="$10"
      animation="bouncyTight"
      scale={1}
      pressStyle={{
        transform: [{ scale: 0.9 }],
      }}
      onPress={onPress}
    >
      <Text
        color="$inputTextColorPrimary"
        key={label}
        fontWeight="500"
        fontSize="$4"
      >
        {label}
      </Text>
      <ChevronRightIcon
        color="$inputTextColorPrimary"
        padding="$0"
        strokeWidth={1.5}
      ></ChevronRightIcon>
    </XStack>
  );
};
