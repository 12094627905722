import React, { useState } from "react";
import { useRouter } from "solito/router";

import { IssueType } from "@medbillai/graphql-types";

import { Paragraph, YBlock, YStack } from "@medbillai/ui";

import { issuePreviewUrl } from "@medbillai/utils/urls";

import CaseInput from "../../case/components/CaseInput";
import { HomePageIssueOverlay } from "../../case/components/new-issue-overlay/HomePageIssueOverlay";
import { WidgetHeader } from "./WidgetHeader";

export const MessageWidget = () => {
  const { push } = useRouter();
  const [selectedMessage, setSelectedMesssage] = useState<string | undefined>(
    undefined,
  );
  const [hidePrompt, setHidePrompt] = useState<boolean>(false);

  const selectMessageSuggestion = (title: string) => {
    setSelectedMesssage(title);
  };

  // TODO: add upload capability once the document upload provider is refreshed
  function openUploadSheet(): void {
    return;
  }

  const executeSendMessage = (title: string) => {
    push(issuePreviewUrl(IssueType.UNKNOWN, title));
  };

  const promptHandler = (hidden: boolean) => {
    setHidePrompt(hidden);
  };

  return (
    <>
      <YBlock
        paddingVertical="$4"
        borderRadius="$5"
        paddingHorizontal="$0"
        height={320}
        maxHeight={320}
        justifyContent="flex-start"
        borderColor="#F3F4F6"
        borderWidth="$1"
      >
        <YStack>
          <WidgetHeader label="How can we help you?" />
          <Paragraph color="$inputTextColorPrimary" paddingHorizontal="$4">
            Our team of experts is here to resolve all your medical billing or
            health insurance issues.
          </Paragraph>
        </YStack>
        <YStack height="$11" jc="center">
          <HomePageIssueOverlay
            hidePrompt={hidePrompt}
            selectMessageSuggestion={selectMessageSuggestion}
          />
        </YStack>
        <CaseInput
          handleSendMessage={executeSendMessage}
          hidePrompt={promptHandler}
          sendMessageLoading={false}
          uploadDocumentLoading={false}
          openUploadSheet={openUploadSheet}
          suggestedMessage={selectedMessage}
          homePage={true}
        />
      </YBlock>
    </>
  );
};
