export const messageSuggestionsRow1 = [
  {
    title: "🧾 How do I dispute a medical bill?",
    message: "How do I dispute a medical bill?",
  },
  {
    title: "📅 Can I set up a payment plan for this bill?",
    message: "Can I set up a payment plan for this bill?",
  },
  {
    title: "📝 What documents are needed for an insurance claim?",
    message: "What documents are needed for an insurance claim?",
  },
  {
    title: "🏥 Is this procedure covered by my insurance?",
    message: "Is this procedure covered by my insurance?",
  },
  {
    title: "⏳ How long does insurance claim processing take?",
    message: "How long does insurance claim processing take?",
  },
  {
    title: "📄 What does this charge on my bill mean?",
    message: "What does this charge on my bill mean?",
  },
  {
    title: "🧑‍⚕️ Find me a doctor that takes my insurance.",
    message: "Find me a doctor that takes my insurance?.",
  },
  {
    title: "📧 How can I contact my insurance provider?",
    message: "How can I contact my insurance provider?",
  },
  {
    title: "🩺 Help me understand and optimize my health benefits.",
    message: "Help me understand and optimize my health benefits.",
  },
  {
    title: "🕵️ Why was my insurance claim denied?",
    message: "Why was my insurance claim denied?",
  },
  {
    title: "🏷️ Are there discounts for my medical bills?",
    message: "Are there discounts for my medical bills?",
  },
  {
    title: "💳 Can I use my HSA to pay this bill?",
    message: "Can I use my HSA to pay this bill?",
  },
  {
    title: "🔄 My insurance denied a claim for care I received, what can I do?",
    message: "My insurance denied a claim for care I received, what can I do?",
  },
  {
    title: "💸 Why is my medical bill so expensive?",
    message: "Why is my medical bill so expensive?",
  },
  {
    title: "🔒 How do I protect my health information?",
    message: "How do I protect my health information?",
  },
];

export const messageSuggestionsRow2 = [
  {
    title: "🔒 How do I protect my health information?",
    message: "How do I protect my health information?",
  },
  {
    title: "📝 What documents are needed for an insurance claim?",
    message: "What documents are needed for an insurance claim?",
  },
  {
    title: "🔄 My insurance denied a claim for care I received, what can I do?",
    message: "My insurance denied a claim for care I received, what can I do?",
  },
  {
    title: "🧾 How do I dispute a medical bill?",
    message: "How do I dispute a medical bill?",
  },
  {
    title: "📧 How can I contact my insurance provider?",
    message: "How can I contact my insurance provider?",
  },
  {
    title: "🏷️ Are there discounts for medical bills?",
    message: "Are there discounts for medical bills?",
  },
  {
    title: "🧑‍⚕️ Find me a doctor that takes my insurance.",
    message: "Find me a doctor that takes my insurance.",
  },
  {
    title: "🩺 Help me understand and optimize my health benefits.",
    message: "Help me understand and optimize my health benefits.",
  },
  {
    title: "📅 Can I set up a payment plan for this bill?",
    message: "Can I set up a payment plan for this bill?",
  },
  {
    title: "⏳ How long does insurance claim processing take?",
    message: "How long does insurance claim processing take?",
  },
  {
    title: "📄 What does this charge on my bill mean?",
    message: "What does this charge on my bill mean?",
  },
  {
    title: "💸 Why is my medical bill so expensive?",
    message: "Why is my medical bill so expensive?",
  },
  {
    title: "🕵️ Why was my insurance claim denied?",
    message: "Why was my insurance claim denied?",
  },
  {
    title: "💳 Can I use my HSA to pay this bill?",
    message: "Can I use my HSA to pay this bill?",
  },
  {
    title: "🏥 Is this procedure covered by my insurance?",
    message: "Is this procedure covered by my insurance?",
  },
];
