import React, { type ReactElement } from "react";
import { type StackProps, Text, type TextProps, XStack } from "tamagui";

export type PillIconProps = {
  text: string;
  icon?: ReactElement;
  wrapperProps?: StackProps;
  textProps?: TextProps;
};

export const PillIcon = ({
  text,
  icon: Icon,
  wrapperProps,
  textProps,
}: PillIconProps) => {
  return (
    <XStack
      ai="center"
      jc="space-evenly"
      br="$2"
      p="$2"
      gap="$1.5"
      bg="$color1"
      {...wrapperProps}
    >
      {Icon}
      <Text fontSize="$1" zi={2} fontWeight="500" {...textProps}>
        {text}
      </Text>
    </XStack>
  );
};
