import { useQuery } from "@apollo/client";
import React, { memo } from "react";

import { ScreenView, type ScreenViewProps } from "../../components/ScreenView";
import { errorHandling } from "../../lib/apollo/utils";
import { useNormalizedEobs } from "../bill/utils/useNormalizedEobs";
import { getIssuesQuery } from "../cases/utils/queries";
import { BillsWidget } from "./components/BillsWidget";
import { CasesWidget } from "./components/CasesWidget";
import { MessageWidget } from "./components/MessageWidget";

const BillsWidgetMemo = memo(BillsWidget);
const MessageWidgetMemo = memo(MessageWidget);
const CasesWidgetMemo = memo(CasesWidget);

export type HomeScreenProps = {
  screenViewProps?: Omit<ScreenViewProps, "children">;
};

export function HomeScreen({ screenViewProps }: HomeScreenProps) {
  const {
    loading: billsLoading,
    data: billsData,
    refetch: refetchBills,
  } = useNormalizedEobs({
    initialFilter: {
      all: true,
      first: 5,
    },
  });
  const {
    loading: issuesLoading,
    data: issuesData,
    refetch: refetchIssues,
  } = useQuery(getIssuesQuery, {
    context: errorHandling("error-screen"),
    variables: {
      input: {
        open: true,
        first: 5,
      },
    },
  });

  const handleRefresh = async () => {
    await Promise.all([refetchBills(), refetchIssues()]);
  };

  return (
    <ScreenView
      stackProps={{ padding: "$4", backgroundColor: "#F9FAFF" }}
      onRefresh={handleRefresh}
      {...screenViewProps}
    >
      <MessageWidgetMemo />
      <BillsWidgetMemo loading={billsLoading} data={billsData} />
      <CasesWidgetMemo loading={issuesLoading} data={issuesData} />
    </ScreenView>
  );
}
